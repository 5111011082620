import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap"
import { Formik } from 'formik';
import * as Yup from 'yup';

import { signup } from "../api/user";
import { showErrorToast } from "../utils/toast";

const Signup = () => {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const schema = Yup.object({
        name: Yup.string().required(),
        email: Yup.string().email(),
        password: Yup.string().required()
    });

    const handleCreateAccountClicked = async (data) => {
        try {
            const result = await signup(data);
            if (!result || result.is_error) {
                showErrorToast("Error creating account!");
                console.error(result);
            }
            setShowSuccessMessage(true);
        } catch (error) {
            showErrorToast("Error creating account!");
            console.error(error);
        }
    };

    return (
        <Container>
            <Row>
                <Col sm={10} lg={5} className="mx-auto my-5">
                    <Card body>
                        {
                            !showSuccessMessage && <Formik
                                onSubmit={handleCreateAccountClicked}
                                validationSchema={schema}
                                initialValues={{
                                    name: '',
                                    email: '',
                                    password: ''
                                }}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <div className="mb-5">
                                            <img src="logo.png" alt="CBF Logo" className="d-block mx-auto w-50" />
                                        </div>

                                        <div>
                                            <h5>Signup for an account</h5>
                                        </div>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={touched.name && errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={touched.email && errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                isInvalid={touched.password && errors.password}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                        </Form.Group>

                                        <div>
                                            <Button variant="primary" type="submit" className="d-block w-100">
                                                CREATE ACCOUNT
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        }

                        {
                            showSuccessMessage && <div className="mt-2 mb-2">
                                <p>Your account has been created.</p>
                                <p>You will receive a verification email once your account has been reviewed and activated by a moderator. Follow the instruction in that email to access your account.</p>
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </Container >
    );
};

export default Signup;