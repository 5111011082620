import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap"
import { Formik } from 'formik';
import * as Yup from 'yup';

import { sendResetLink } from "../api/user";
import { showErrorToast, showSuccessToast } from "../utils/toast";

const ForgotPassword = () => {
    const navigate = useNavigate();

    const schema = Yup.object({
        email: Yup.string().required().email()
    });

    const handleSendClicked = async (data) => {
        try {
            const { email } = data;
            const result = await sendResetLink(email);
            if (!result || result.is_error) {
                showErrorToast("Invalid email!");
                console.error(result);
                return;
            }
            navigate(`/login`);
            showSuccessToast(`Reset password link sent to ${data.email}`);
        } catch (error) {
            showErrorToast("Invalid email!");
            console.error(error);
        }
    };

    return (
        <Container>
            <Row>
                <Col sm={10} lg={5} className="mx-auto my-5">
                    <Card body>
                        <Formik
                            onSubmit={handleSendClicked}
                            validationSchema={schema}
                            initialValues={{ email: '' }}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div>
                                        <p>
                                            Forgot your password? It's cool, enter you email below and we'll send you an email with a link to help you reset your password.
                                        </p>
                                    </div>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={touched.email && errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>

                                    <div>
                                        <Button variant="primary" type="submit" className="d-block w-100">
                                            SEND LINK
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
};

export default ForgotPassword;