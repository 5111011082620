import { toast } from 'react-toastify';

const toastBaseProps = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
};

export const showSuccessToast = (msg) => {
    toast.success(msg, toastBaseProps);
};

export const showErrorToast = (msg) => {
    toast.error(msg, toastBaseProps);
};