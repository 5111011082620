import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap"
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';

import { auth, verifyUser } from "../api/user";
import { writeCredentials } from "../utils/localstore";
import { showErrorToast, showSuccessToast } from "../utils/toast";

const Login = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const schema = Yup.object({
        email: Yup.string().required(),
        password: Yup.string().required()
    });

    useEffect(() => {
        const token = searchParams.get("token");
        if (token) {
            (async () => {
                try {
                    const result = await verifyUser(token);
                    if (result.error) {
                        showErrorToast("Invalid verification token!");
                        console.error(result);
                        return;
                    }
                    showSuccessToast("Email verified!");
                    setSearchParams({});
                } catch (error) {
                    showErrorToast("Invalid verification token!");
                }
            })();
        }
    }, []);

    const handleLoginClicked = async (data) => {
        try {
            const result = await auth(data);
            if (!result || result.is_error) {
                showErrorToast("Invalid credentials!");
                console.error(result);
                return;
            }
            writeCredentials(result.payload);
            navigate(`/index?page=1`);
        } catch (error) {
            showErrorToast("Invalid credentials!");
            console.error(error);
        }
    };

    return (
        <Container>
            <Row>
                <Col sm={10} lg={5} className="mx-auto my-5">
                    <Card body>
                        <Formik
                            onSubmit={handleLoginClicked}
                            validationSchema={schema}
                            initialValues={{ email: '', password: '' }}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div className="mb-5">
                                        <img src="logo.png" alt="CBF Logo" className="d-block mx-auto w-50" />
                                    </div>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={touched.email && errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={touched.password && errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>

                                    <div>
                                        <Button variant="primary" type="submit" className="d-block w-100">
                                            LOGIN
                                        </Button>
                                    </div>

                                    <div className="text-center mt-1">
                                        <small><Link to="/forgot" className="link-secondary">Forgot password?</Link></small>
                                    </div>

                                    <div className="text-center mt-4">
                                        <div>Don't have an account yet?</div>
                                        <div>
                                            <Link to="/signup" className="link-primary">Create Account</Link>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
};

export default Login;