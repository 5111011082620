import React from "react";

const Copyright = () => {
    const style = {
        position: 'fixed',
        bottom: '10px',
        left: '15px',
        fontSize: '0.8rem'
    };

    const html = `&copy; ${new Date().getFullYear()} CBF`

    return (
        <span
            dangerouslySetInnerHTML={{ "__html": html }}
            style={style}
        />
    );
};

export default Copyright;
