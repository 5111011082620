import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { Container, Offcanvas, Navbar, Nav } from 'react-bootstrap';
import { FaHamburger, FaUserCircle } from 'react-icons/fa';

import { removeCredentials } from "../utils/localstore";
import { USER_TYPE } from "../constants";
import { checkACL } from "../utils/acl";
import Copyright from "./Copyright";
import { readCredentials } from "../utils/localstore";

const Header = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const renderHamburger = () => {
        if (
            window.location.pathname.includes('login') ||
            window.location.pathname.includes('signup') ||
            window.location.pathname.includes('forgot') ||
            window.location.pathname.includes('reset')
        ) {
            return false;
        }
        return true;
    };

    const handleLogoutClicked = () => {
        removeCredentials();
        navigate(`/login`);
        handleClose();
    };

    const goto = (path) => {
        navigate(path);
        handleClose();
    };

    const renderLinks = () => {
        const links = [];
        if (checkACL(USER_TYPE.SUPER_USER)) {
            links.push(["User Management", () => goto("/user-management?page=1")]);
        }
        // links.push(["Profile"]);
        links.push(["Logout", handleLogoutClicked]);

        return links.map((link, index) => {
            return (
                <Nav.Link
                    onClick={link[1]}
                    className="ps-3 pb-3"
                    key={index}
                >
                    {link[0]}
                </Nav.Link>
            )
        });
    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
            <Container>
                {renderHamburger() ? <FaHamburger
                    onClick={() => setShow(true)}
                    style={{ color: 'white', cursor: "pointer" }}
                    className="d-inline-block me-1"
                /> : <div className="d-inline-block"></div>}

                <Navbar.Brand
                    as={Link}
                    to='/'
                    className="d-inline-block"
                >
                    <span>Songbook&nbsp;</span>
                    <small className="text-muted">beta</small>
                </Navbar.Brand>
                <div className="d-inline-block"></div>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>

                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div>
                            <Nav.Link className="ps-3">
                                <span className="align-middle"><FaUserCircle></FaUserCircle></span>
                                <span className="align-middle">&nbsp;&nbsp;{readCredentials().email}</span>
                            </Nav.Link>
                            <hr />
                            {renderLinks()}
                        </div>
                        <div className="footer" style={{ position: 'absolute' }}>
                            <Copyright></Copyright>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

            </Container>
        </Navbar >
    );
};

export default Header;