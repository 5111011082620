import API from './index';
import { errorResponseBuilder } from './utils/errorResponseBuilder';

export async function auth(payload) {
    try {
        const response = await API.post(`/auth`, payload);
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function signup(payload) {
    try {
        const response = await API.post(`/user`, payload);
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function fetchUserList(page = 1, limit = 500) {
    try {
        const response = await API.get('/user', {
            params: {
                page,
                limit
            }
        });
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function inviteUser(id) {
    try {
        const response = await API.post(`/invite/${id}`);
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function updateUser(id, payload) {
    try {
        const response = await API.put(`/user/${id}`, payload);
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function verifyUser(token) {
    try {
        const response = await API.post(`/verify`, { token });
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function sendResetLink(email) {
    try {
        const response = await API.post(`/sendresetlink`, { email });
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function resetPassword(token, password) {
    try {
        const response = await API.post(`/resetpassword`, { token, password });
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}