import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Table } from 'react-bootstrap';

import { fetchSongList } from '../api/song';
import Loader from "../components/Loader";

const SongIndex = () => {
    const navigate = useNavigate();
    const [songList, setSongList] = useState([]);
    const [searchText, setSearchText] = useState("");

    const getSongs = async () => {
        const data = await fetchSongList();
        if (data.is_error) {
            alert("Error loading songlist");
            return;
        };

        setSongList(data.payload.songs);
    };

    useEffect(() => {
        (async () => {
            await getSongs();
        })();
    }, []);

    const handleSongClicked = (id) => {
        navigate(`/song/${id}`);
    };

    const renderRow = () => {
        const filteredList = songList.filter(song => {
            const q = searchText.match(/^\d+$/) ? searchText : searchText.toLowerCase().trim();
            const term = `${song.song_no} : ${song.title.toLowerCase()}`
            return term.includes(q);
        });

        return filteredList.map(song => {
            return (
                <tr key={song._id} onClick={() => handleSongClicked(song._id)} style={{ cursor: "pointer" }}>
                    <td>{`${song.song_no.toString().padStart(3, ' ')}  : ${song.title}`}</td>
                </tr>
            );
        });
    };

    if (!songList.length) {
        return <Loader tall></Loader>;
    }

    return (
        <Container fluid className="pt-3">
            <Container>
                <div className="row mb-3">
                    <div className="col-12">
                        <input
                            type="text"
                            className="form-control w-100"
                            placeholder="Search with a song title or number"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </div>
                <Table responsive hover>
                    <tbody>
                        {renderRow()}
                    </tbody>
                </Table>
            </Container>
        </Container>
    );
};

export default SongIndex;