import axios from 'axios';

import { readCredentials, removeCredentials } from '../utils/localstore';
import { showErrorToast } from '../utils/toast';

const API = axios.create({ baseURL: '/api' });

const requestHandler = request => {
    const credentials = readCredentials();
    if (credentials && credentials.auth_token) {
        try {
            request.headers.Authorization = credentials.auth_token || '';
        } catch (error) {
            // do nothing
            console.error(error);
        }
    }

    return request;
};


const responseHandler = response => {
    return response;
};

const errorHandler = error => {
    if ([401, 403].includes(error.response.status)) {
        showErrorToast('Session expired/invalid, please login again.');
        removeCredentials();

        setTimeout(() => {
            window.location.href = `${window.location.protocol}//${window.location.host}/login`;
        }, 3000);
    }
    return Promise.reject(error);
};

API.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

API.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default API;