import React from "react";
import { Container, Button, Form } from "react-bootstrap"
import { Formik } from 'formik';
import * as Yup from 'yup';

const schema = Yup.object({
    title: Yup.string().required(),
    song_no: Yup.number().required().moreThan(0),
    suggested_key: Yup.string().required().max(2),
    ccli_license: Yup.string().required()
});

const Step1 = ({ data, onSave }) => {
    return (
        <Container fluid>
            <Formik
                onSubmit={onSave}
                initialValues={data}
                validationSchema={schema}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Song Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter song title"
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                isInvalid={touched.title && errors.title}
                            />
                            <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Song Number</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter song number"
                                name="song_no"
                                value={values.song_no}
                                onChange={handleChange}
                                isInvalid={touched.song_no && errors.song_no}
                            />
                            <Form.Control.Feedback type="invalid">{errors.song_no}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Suggested Key</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter suggested key"
                                name="suggested_key"
                                value={values.suggested_key}
                                onChange={handleChange}
                                isInvalid={touched.suggested_key && errors.suggested_key}
                            />
                            <Form.Control.Feedback type="invalid">{errors.suggested_key}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>CCLI License</Form.Label>
                            <Form.Control
                                as="textarea"
                                type="text"
                                placeholder="Enter license details"
                                name="ccli_license"
                                value={values.ccli_license}
                                onChange={handleChange}
                                isInvalid={touched.ccli_license && errors.ccli_license}
                                style={{ height: '200px' }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ccli_license}</Form.Control.Feedback>
                        </Form.Group>


                        <Button variant="primary" type="submit">
                            NEXT
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default Step1;