import React, { useState } from "react";
import { Container, Button } from "react-bootstrap"
import Editor from 'react-simple-code-editor';
import { highlight } from 'prismjs/components/prism-core';

import songmd from "./songmd";
import './editor.css';

const Step2 = ({ data, onSave }) => {
    const [lyrics, setLyrics] = useState(data.lyrics || "");
    const [isInvalid, setIsInvalid] = useState(false);

    const handleSubmit = () => {
        if (!lyrics) {
            setIsInvalid(true);
            return;
        }
        onSave(lyrics);
    };

    return (
        <Container fluid>
            <h4>Enter Lyrics</h4>
            <Editor
                value={lyrics}
                onValueChange={lyrics => setLyrics(lyrics)}
                highlight={lyrics => highlight(lyrics, songmd)}
                padding={10}
                style={{
                    fontFamily: '"Fira code", monospace',
                    fontSize: 16,
                }}
            />
            {
                isInvalid && <p className="text-danger">Lyrics required</p>
            }
            <Button variant="primary" type="button" onClick={handleSubmit}>
                NEXT
            </Button>
        </Container>
    );
};

export default Step2;