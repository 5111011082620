import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Pages
import Login from "./pages/Login";
import Home from './pages/Home';
import SongIndex from "./pages/SongIndex";
import SongView from './pages/SongView';
import SongEdit from './pages/SongEdit';
import Signup from "./pages/Signup";
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

// Components
import Header from './components/Header';
import Auth from './Auth';
import UserManagement from './pages/UserManagement';

const App = () => {
    return (
        <Router>
            <Header />
            <div className="container-fluid">
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/signup' element={<Signup />} />
                    <Route path="/forgot" element={<ForgotPassword />} />
                    <Route path="/reset" element={<ResetPassword />} />

                    <Route path='/' element={<Auth />}>
                        <Route path='/' element={<Home />} />
                    </Route>
                    <Route path='/index' element={<Auth />}>
                        <Route path='/index' element={<SongIndex />} />
                    </Route>
                    <Route path='/song/:id' element={<Auth />}>
                        <Route path='/song/:id' element={<SongView />} />
                    </Route>
                    <Route path='/song/edit/:id' element={<Auth />}>
                        <Route path='/song/edit/:id' element={<SongEdit />} />
                    </Route>

                    <Route path='/user-management' element={<Auth />}>
                        <Route path='/user-management' element={<UserManagement />} />
                    </Route>
                </Routes>
            </div>

            <ToastContainer></ToastContainer>
        </Router>
    )
};

export default App;