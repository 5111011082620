import React from "react";

const PageTitle = ({ line }) => {
    // const displayText = line.replaceAll('#', '').trim();

    return <div></div>;

    /*
    return (
        <div
            className="page-title text-capitalize display-6 mb-3"
            style={{ fontSize: '1.4em' }}
            dangerouslySetInnerHTML={{ __html: displayText }}>
        </div>
    );
    */
}

export default PageTitle;