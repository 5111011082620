import React from "react";

const Loader = ({ align, size, tall }) => {
    let style = {};

    if (tall) {
        style.display = 'flex';
        style.minHeight = '300px';
        style.justifyContent = 'center';
        style.alignItems = 'center';
    }

    return (
        <div className={`text-${align ? align : 'center'}`} style={style}>
            <div className={`spinner-border  ${size === 'small' ? 'spinner-border-sm' : ''}`} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default Loader;