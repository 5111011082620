export function errorResponseBuilder(error) {
    if (typeof error === 'object' && 'error' in error && 'message' in error) {
        return error;
    }

    return {
        error: true,
        message: 'ERROR',
        details: error
    };
}