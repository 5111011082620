import React, { useState } from "react";
import { Container, Button } from "react-bootstrap"
import Editor from 'react-simple-code-editor';
import { highlight } from 'prismjs/components/prism-core';

import songmd from "./songmd";
import ChordSheet from "../ChordSheet";
import './editor.css';



const Step3 = ({ data, onSave, title }) => {
    // if there are no chords, use the lyrics instead
    const [chordSheet, setChordSheet] = useState(data.chord_sheet || data.lyrics);
    const [showChordSheet, setShowChordSheet] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [previewOpened, setPreviewOpened] = useState(false);

    const handleSubmit = () => {
        if (!chordSheet) {
            setIsInvalid(true);
            return;
        }
        onSave(chordSheet);
    };

    const handlePreviewClicked = () => {
        setPreviewOpened(true);
        setShowChordSheet(true);
    };

    return (
        <Container fluid>
            <h4>Enter Chords</h4>
            <Editor
                value={chordSheet}
                onValueChange={chordSheet => setChordSheet(chordSheet)}
                highlight={chordSheet => highlight(chordSheet, songmd)}
                insertSpaces={true}
                tabSize={1}
                style={{
                    fontFamily: '"Fira code", monospace',
                    fontSize: 16,
                }}
            />
            {
                isInvalid && <p className="text-danger">Chord-sheet required</p>
            }
            <Button className="me-1" variant="secondary" type="button" onClick={handlePreviewClicked}>
                PREVIEW
            </Button>
            <Button disabled={!previewOpened} variant="primary" type="button" onClick={handleSubmit}>
                SAVE
            </Button>
            <ChordSheet title={`Previewing>> ${title}`}
                chords={chordSheet}
                show={showChordSheet}
                onClose={() => setShowChordSheet(false)}>
            </ChordSheet>
        </Container>
    );
};

export default Step3;