import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap"
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';

import { resetPassword } from "../api/user";
import { showErrorToast, showSuccessToast } from "../utils/toast";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const schema = Yup.object({
        password: Yup.string().required()
    });

    const handleResetClicked = async (data) => {
        try {
            const { password } = data;
            const result = await resetPassword(searchParams.get("token"), password);
            if (!result || result.is_error) {
                showErrorToast("Invalid credentials!");
                return;
            }
            showSuccessToast("Password reset, please login")
            navigate(`/login`);
        } catch (error) {
            showErrorToast("Invalid credentials!");
            console.error(error);
        }
    };

    return (
        <Container>
            <Row>
                <Col sm={10} lg={5} className="mx-auto my-5">
                    <Card body>
                        <Formik
                            onSubmit={handleResetClicked}
                            validationSchema={schema}
                            initialValues={{ password: '' }}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={touched.password && errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Form.Group>

                                    <div>
                                        <Button variant="primary" type="submit" className="d-block w-100">
                                            RESET PASSWORD
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
};

export default ResetPassword;