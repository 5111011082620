import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/index?page=1`);
    });

    return (<div></div>);
};

export default Home;