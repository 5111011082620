const CREDENTIALS = 'credentials';

export const writeCredentials = (data) => {
    try {
        localStorage.setItem(CREDENTIALS, JSON.stringify(data));
    } catch (error) {
        console.error(error);
    }
};

export const readCredentials = () => {
    try {
        const data = localStorage.getItem(CREDENTIALS) || "{}";
        return JSON.parse(data);
    } catch (error) {
        console.error(error);
    }
};

export const removeCredentials = () => {
    try {
        localStorage.removeItem(CREDENTIALS);
    } catch (error) {
        console.error(error);
    }
}
