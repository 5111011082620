import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap"
import { GiMusicalScore } from "react-icons/gi";
import { MdOutlineEditNote, MdArrowBackIosNew } from "react-icons/md";

import { fetchSong } from "../api/song";
import Loader from "../components/Loader";
import ChordSheet from "../components/ChordSheet";
import { checkACL } from "../utils/acl";
import { USER_TYPE } from "../constants";

const SongView = () => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const [song, setSong] = useState(null);
    const [showChordSheet, setShowChordSheet] = useState(false);

    useEffect(() => {
        (async () => {
            const data = await fetchSong(routeParams.id);
            if (data.is_error) {
                alert("Error loading song");
                return;
            };

            setSong(data.payload);
        })().catch(err => console.error(err));
    }, [routeParams.id]);

    const printLyrics = (lyrics) => {
        const lines = lyrics.split('\n');
        return lines.map((l, idx) => {
            if (l.charAt(0) === "[") {
                return <div className="mt-3 fw-bold" key={idx}>{l.replace("[", "").replace("]", "")}</div>
            }
            return <div key={idx}>{l}</div>
        });
    };

    const printLicense = (license) => {
        const lines = license.split('\n');
        return lines.map((l, idx) => {
            return <div key={idx}>{l}</div>
        });
    }

    const goto = (path) => navigate(path);

    if (!song) {
        return <Loader tall></Loader>;
    }

    return (
        <>
            <Container className="pt-3 pb-5 mb-3" style={{ minHeight: '100vh' }}>
                <h2 className="d-flex justify-content-between">
                    <div>
                        {`${song.song_no}. ${song.title}`}
                    </div>
                    <div>
                        <Button
                            className="me-1 pb-2"
                            onClick={() => setShowChordSheet(true)}
                            title="Chords"
                        >
                            <GiMusicalScore></GiMusicalScore>
                        </Button>
                        {checkACL(USER_TYPE.MODERATOR) && <Button
                            className="pb-2"
                            onClick={() => navigate(`/song/edit/${song._id}`)}
                            title="Edit"
                        >
                            <MdOutlineEditNote></MdOutlineEditNote>
                        </Button>}
                    </div>
                </h2>
                <h5>{`Key: ${song.suggested_key}`}</h5>
                <div>{printLyrics(song.lyrics)}</div>
                <div className="mt-4 fs-6 fw-light fst-italic">
                    {printLicense(song.ccli_license)}
                </div>
                <ChordSheet
                    show={showChordSheet}
                    title={`${song.song_no}. ${song.title}`}
                    suggestedKey={song.suggested_key}
                    chords={song.chord_sheet}
                    onClose={() => setShowChordSheet(false)}>
                </ChordSheet>

                <div className="position-fixed bottom-0 start-0 end-0">
                    <div className="container">
                        <div className="d-flex justify-content-between bg-white py-2">
                            <div className="d-inline-block">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-dark" onClick={() => goto('/')}>
                                        {'INDEX'}
                                    </button>
                                </div>
                            </div>
                            <div className="d-inline-block">
                                <div className="btn-group" role="group">
                                    <button type="button"
                                        className="btn btn-dark"
                                        disabled={!song.previousSongId}
                                        onClick={() => goto(`/song/${song.previousSongId}`)}
                                    >
                                        PREV SONG
                                    </button>
                                    <button type="button"
                                        className="btn btn-dark"
                                        disabled={!song.nextSongId}
                                        onClick={() => goto(`/song/${song.nextSongId}`)}
                                    >
                                        NEXT SONG
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default SongView;