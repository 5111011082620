import React from "react";
import { Navigate, Outlet } from 'react-router-dom';

import { readCredentials } from "./utils/localstore";

const Auth = () => {
    const credentials = readCredentials();
    const user = credentials?.auth_token ? true : false;
    return user ? <Outlet /> : <Navigate to='/login' />;
};

export default Auth;
