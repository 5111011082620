import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { Container, Table, Pagination, Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Loader from "../components/Loader";
import { fetchUserList, inviteUser, updateUser } from "../api/user";
import { USER_TYPE } from "../constants";

const UserManagement = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [userList, setUserList] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [page, setPage] = useState(searchParams.get("page") ? parseInt(searchParams.get("page")) : 1);
    const [loading, setLoading] = useState(false);

    const [selectedUser, setSelectedUser] = useState();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);

    const schema = Yup.object({
        name: Yup.string().required(),
        email: Yup.string().email(),
        password: Yup.string().required()
    });

    const getUsers = async () => {
        const data = await fetchUserList(page)
        if (data.is_error) {
            alert("Error loading userlist");
            return;
        };

        setUserList(data.payload.users);
        setTotalPages(data.payload.totalPages);
        setSearchParams({ page });
    };

    useEffect(() => {
        (async () => {
            await getUsers();
        })();
    }, [page]);

    const handleEditClicked = (user) => {
        setSelectedUser(user)
        setShowEditModal(true);
    };

    const handleInviteClicked = (user) => {
        setSelectedUser(user);
        setShowInviteModal(true);
    }

    const getNextPage = () => {
        setUserList([]);
        setPage(page + 1);
        setSearchParams({ page });
    };

    const getPreviousPage = () => {
        setUserList([]);
        setPage(page - 1);
        setSearchParams({ page });
    };

    const getUserTypeLabel = (value) => {
        for (const type in USER_TYPE) {
            if (USER_TYPE[type] === value) {
                return type.replace("_", " ").toLowerCase();
            }
        }
        return '';
    };

    const handleUpdateClicked = async (payload) => {
        setLoading(true);
        const data = await updateUser(selectedUser._id, payload);
        if (data.is_error) {
            alert("Error updating user details!");
            setLoading(false);
            return;
        };

        // update user's info inplace
        const idx = userList.findIndex(user => user._id === selectedUser._id);
        userList[idx].email = payload.email;
        userList[idx].name = payload.name;
        userList[idx].type = parseInt(payload.type);

        setLoading(false);
        setShowEditModal(false);
    };

    const handleInviteUserClicked = async () => {
        setLoading(true);
        const data = await inviteUser(selectedUser._id);
        if (data.is_error) {
            alert("Error updating user details!");
            setLoading(false);
            return;
        };

        // update user's info inplace
        const idx = userList.findIndex(user => user._id === selectedUser._id);
        userList[idx].type = USER_TYPE.END_USER;
        userList[idx].is_verified = false;

        setLoading(false);
        setShowInviteModal(false);
    }

    const renderRow = () => {
        return userList.map(user => {
            return (
                <tr key={user._id}>
                    <td className="text-capitalize align-middle">{user.name}</td>
                    <td className="align-middle">{user.email}</td>
                    <td className="text-capitalize align-middle">
                        {getUserTypeLabel(user.type)}
                        {
                            !user.is_verified && user.type !== USER_TYPE.DISABLED_USER ?
                                <>
                                    <br></br>
                                    <span className="fst-italic fw-light">verification pending</span>
                                </>
                                :
                                <></>
                        }
                    </td>
                    <td className="align-middle">
                        {
                            user.type === USER_TYPE.DISABLED_USER ?
                                <Button className="w-100" variant="outline-secondary" onClick={() => handleInviteClicked(user)}>
                                    ENABLE
                                </Button>
                                :
                                <Button className="w-100" variant="outline-secondary" onClick={() => handleEditClicked(user)}>
                                    EDIT
                                </Button>
                        }
                    </td>
                </tr>
            );
        });
    };

    if (!userList.length) {
        return <Loader tall></Loader>;
    }

    return (
        <Container fluid className="pt-3">
            <Container>
                <h3 className="ps-1">User Management</h3>
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>Quick Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRow()}
                    </tbody>
                </Table>
                {/* <Pagination>
                    <Pagination.Prev disabled={page === 1} onClick={getPreviousPage} />
                    <Pagination.Next disabled={page === totalPages} onClick={getNextPage} />
                </Pagination> */}
            </Container>

            {/* ================== EDIT MODAL ================== */}
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >

                {
                    selectedUser && <Formik
                        onSubmit={handleUpdateClicked}
                        validationSchema={schema}
                        initialValues={{
                            name: selectedUser.name,
                            email: selectedUser.email,
                            type: selectedUser.type
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => {
                            return (
                                <>
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Edit Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={touched.name && errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={touched.email && errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>User Type</Form.Label>
                                                <div>
                                                    <Form.Check
                                                        inline
                                                        label="Disabled User"
                                                        name="type"
                                                        type="radio"
                                                        id={`radio-${USER_TYPE.DISABLED_USER}`}
                                                        value={USER_TYPE.DISABLED_USER}
                                                        onChange={handleChange}
                                                        defaultChecked={selectedUser.type === USER_TYPE.DISABLED_USER}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="End User"
                                                        name="type"
                                                        type="radio"
                                                        id={`radio-${USER_TYPE.END_USER}`}
                                                        value={USER_TYPE.END_USER}
                                                        onChange={handleChange}
                                                        defaultChecked={selectedUser.type === USER_TYPE.END_USER}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Moderator"
                                                        name="type"
                                                        type="radio"
                                                        id={`radio-${USER_TYPE.MODERATOR}`}
                                                        value={USER_TYPE.MODERATOR}
                                                        onChange={handleChange}
                                                        defaultChecked={selectedUser.type === USER_TYPE.MODERATOR}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Super User"
                                                        name="type"
                                                        type="radio"
                                                        id={`radio-${USER_TYPE.SUPER_USER}`}
                                                        value={USER_TYPE.SUPER_USER}
                                                        onChange={handleChange}
                                                        defaultChecked={selectedUser.type === USER_TYPE.SUPER_USER}
                                                    />
                                                </div>
                                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                                CANCEL
                                            </Button>
                                            <Button variant="primary" onClick={() => handleUpdateClicked(values)} disabled={loading}>
                                                UPDATE
                                            </Button>
                                        </Modal.Footer>
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                }
            </Modal>
            {/* ================= END EDIT MODAL ================ */}

            {/* ================== INVITE MODAL ================== */}
            <Modal
                show={showInviteModal}
                onHide={() => setShowInviteModal(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invite?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to invite<span className="text-capitalize"> {selectedUser?.name}</span> ({selectedUser?.email})?
                    </p>
                    <p>PS: Inviting a user will set their user type to "End User"</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowInviteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleInviteUserClicked} disabled={loading}>
                        Invite
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* ================= END INVITE MODAL ================ */}
        </Container >
    );
};

export default UserManagement;