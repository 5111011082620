import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import { fetchSong, updateSong } from "../api/song";
import Loader from "../components/Loader";
import Step1 from "../components/Editor/Step1";
import Step2 from "../components/Editor/Step2";
import Step3 from "../components/Editor/Step3";
import { showErrorToast, showSuccessToast } from "../utils/toast";

const SongView = () => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const [song, setSong] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const data = await fetchSong(routeParams.id);
            if (data.is_error) {
                showErrorToast("Error loading song");
                return;
            };

            setSong(data.payload);
        })().catch(err => console.error(err));
    }, []);

    const saveStep1 = async (data) => {
        // filter out only keys from step one
        // this is sketchy, there needs to be a check on the backend
        const { song_no, title, suggested_key, ccli_license } = data;
        setLoading(true);
        try {
            const result = await updateSong(song._id, {
                song_no,
                title,
                suggested_key,
                ccli_license
            });

            if (result.is_error) {
                showErrorToast("Error updating song");
                return;
            };

            showSuccessToast('Song updated!');
            setSong({
                ...song,
                song_no,
                title,
                suggested_key,
                ccli_license
            });
            setCurrentStep(2);
        } catch (error) {
            showErrorToast("Error updating song");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const saveStep2 = async (lyrics) => {
        setLoading(true);
        try {
            const result = await updateSong(song._id, {
                lyrics
            });

            if (result.is_error) {
                showErrorToast("Error updating song");
                return;
            };

            showSuccessToast('Song updated!');
            setSong({ ...song, lyrics });
            setCurrentStep(3);
        } catch (error) {
            showErrorToast("Error updating song");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const saveStep3 = async (chord_sheet) => {
        setLoading(true);
        try {
            const result = await updateSong(song._id, {
                chord_sheet
            });

            if (result.is_error) {
                showErrorToast("Error updating song");
                return;
            };

            showSuccessToast('Song updated!');
            navigate(`/song/${song._id}`);
        } catch (error) {
            showErrorToast("Error updating song");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const showWizard = () => {
        switch (currentStep) {
            case 1: return <Step1 data={song} onSave={saveStep1}></Step1>;
            case 2: return <Step2 data={song} onSave={saveStep2}></Step2>;
            case 3: return <Step3 data={song} onSave={saveStep3} title={`${song.song_no}. ${song.title}`}></Step3>;
        }
    };

    if (!song || loading) {
        return <Loader tall></Loader>;
    }

    return (
        <Container className="pt-3">
            <h2 className="d-flex justify-content-between">
                <div>
                    {`Editing >> ${song.song_no}. ${song.title}`}
                </div>
            </h2>
            <div className="card p-3">
                {showWizard()}
            </div>
        </Container>
    );
};

export default SongView;