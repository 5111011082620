import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Container } from "react-bootstrap";
import { FaStop, FaPlay } from "react-icons/fa";

import Line from "./Line";
import { getLineType } from "./Line/lineOps";

const ChordSheet = ({ title, suggestedKey, chords, show, onClose }) => {
    const navigate = useNavigate();
    const [fontSize, setFontSize] = useState(30);
    const [transposeOffset, setTransposeOffset] = useState(0);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);
    const [scrollSpeed, setScrollSpeed] = useState(1);
    const [scrollIntervalId, setScrollIntervalId] = useState();
    const lyricWindow = useRef();

    const handleTranspose = (offset) => {
        setAutoScrollEnabled(false);
        setTransposeOffset(offset % 12);
    };

    const handleAutoScrollClicked = () => {
        let intervalId;

        if (!autoScrollEnabled) {
            setAutoScrollEnabled(true);

            intervalId = setInterval(() => {
                lyricWindow.current.scrollBy(0, 1 * scrollSpeed);
            }, 100);

            setScrollIntervalId(intervalId);
        } else {
            setAutoScrollEnabled(false);
            scrollIntervalId && clearInterval(scrollIntervalId);
        }
    };

    const handleScrollSpeedClicked = (direction) => {
        if (direction === '+') {
            setScrollSpeed(scrollSpeed + 0.2);
        } else if (direction === '-' && scrollSpeed > 0.7) {
            setScrollSpeed(scrollSpeed - 0.2);
        }
    }

    const handleFontSizeClicked = (offset) => {
        setAutoScrollEnabled(false);
        setFontSize(fontSize + offset)
    }

    const renderLines = (data) => {
        const lines = data.split('\n');
        return lines.map((line, index) => {
            const lineType = getLineType(lines[index]);
            return <Line
                text={line}
                key={index}
                lineType={lineType}
                transposeOffset={transposeOffset}
            />;
        });
    }

    return (
        <Modal show={show} onHide={onClose} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title className="text-center text-capitalize w-100">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body ref={lyricWindow}>
                <>
                    {/* <Container>
                        <h5>{`Original Key: ${suggestedKey}`}</h5>
                    </Container> */}
                    <Container style={{ fontFamily: '"Fira code", monospace' }}>
                        {chords ?
                            renderLines(chords) :
                            <div className="pt-5 text-center fw-bold">No chords for this song :(</div>
                        }
                    </Container>
                </>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <div className="btn-group" role="group">
                    <button type="button" className="btn btn-outline-dark" onClick={() => navigate('/')}>INDEX</button>
                </div>


                {/* TRANSPOSE CONTROLS */}
                <div className="btn-group" role="group">
                    <button type="button" className="btn btn-dark" onClick={() => handleTranspose(transposeOffset - 1)}>-</button>
                    <button type="button" className="btn btn-dark" onDoubleClick={() => handleTranspose(0)}>
                        TRANSPOSE&nbsp;
                        {transposeOffset !== 0 && <sup>[{transposeOffset}]</sup>}
                    </button>
                    <button type="button" className="btn btn-dark" onClick={() => handleTranspose(transposeOffset + 1)}>+</button>
                </div>

                {/* AUTOSCROLL CONTROLS */}
                {/* <div className="btn-group" role="group">
                    <button type="button"
                        className={`btn ${autoScrollEnabled ? 'btn-outline-success' : 'btn-outline-dark'}`}
                        onClick={() => handleScrollSpeedClicked('-')}
                        disabled={autoScrollEnabled}
                    >
                        <span>-</span>
                    </button>
                    <button
                        type="button"
                        className={`btn ${autoScrollEnabled ? 'btn-outline-success' : 'btn-outline-dark'}`}
                        onClick={handleAutoScrollClicked}
                    >
                        <span className="align-text-bottom">{autoScrollEnabled ? <FaStop /> : <FaPlay />}</span>
                        <span>&nbsp;AUTOSCROLL&nbsp;</span>
                        <span><sup>{scrollSpeed.toFixed(1)}x</sup></span>
                    </button>
                    <button type="button"
                        className={`btn ${autoScrollEnabled ? 'btn-outline-success' : 'btn-outline-dark'}`}
                        onClick={() => handleScrollSpeedClicked('+')}
                        disabled={autoScrollEnabled}
                    >
                        <span>+</span>
                    </button>
                </div> */}

                {/* FONT SIZE CONTROLS */}
                {/* <div className="btn-group" role="group">
                    <button type="button" className="btn btn-dark" onClick={() => handleFontSizeClicked(-1)}>-</button>
                    <button type="button" className="btn btn-dark" style={{ pointerEvents: 'none' }}>FONT</button>
                    <button type="button" className="btn btn-dark" onClick={() => handleFontSizeClicked(+1)}>+</button>
                </div> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ChordSheet;