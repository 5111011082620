const notes = "[CDEFGAB]",
    accidentals = "(b|bb)?",
    chords = "(m|maj7|maj|min7|min|sus)?",
    suspends = "(1|2|3|4|5|6|7|8|9)?",
    sharp = "(#)?",
    chordsRegex = new RegExp("\\b" + notes + accidentals + chords + suspends + "\\b" + sharp, "g");

const songmd = {
    "section-title": {
        pattern: /^\[.*\]$/gm,
        lookbehind: true,
        greedy: true
    },
    // "chords": {
    //     pattern: chordsRegex,
    //     lookbehind: true,
    //     greedy: true
    // }
};


// sectionTitle = /^\[.*\]$/;
// pagetitle = /^#.*/;
// chords = /^\{.*\}$/;
// blockquotes = /^>.*/;
// code = /^`.*`$/;

export default songmd;