import API from './index';
import { errorResponseBuilder } from './utils/errorResponseBuilder';

export async function fetchSongList(page = 1, limit = 50) {
    try {
        const response = await API.get('/song', {
            params: {
                page,
                limit
            }
        });
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function fetchSong(id) {
    try {
        const response = await API.get(`/song/${id}`);
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}

export async function updateSong(id, payload) {
    try {
        const response = await API.put(`/song/${id}`, payload);
        return response.data;
    } catch (error) {
        return errorResponseBuilder((error.response && error.response.data) || error);
    }
}